$system-font: monospace,
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
Helvetica,
sans-serif;

@import-normalize ; /* bring in normalize.css styles */
/*Normalizers*/

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
}
* {
  font-family: $system-font;
}

body {
  margin: 0;
  overflow: hidden;
}

a:focus {
  outline: thin dotted;
}

a:active, a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
  color: #000;
}

code, kbd, pre, samp {
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
blockquote , figure{
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  margin: 0;
}
p {
  margin-block-start:  0;
  margin-block-end:    0;
}
/* https://github.com/filamentgroup/select-css */

/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
.custom-select {
  position: relative;
  display: block;
}

/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.custom-select select {
  width: 100%;
  margin: 0;
  outline: none;
  padding: .6em .8em .5em .8em;

  /* Prefixed box-sizing rules necessary for older browsers */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /* Font size must be 16px to prevent iOS page zoom on focus */
  font-size: 16px;
}


/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
 * etc. or the arrow could just baked into the bg image on the select. */
.custom-select::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 2;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
  display: none;
}

/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
 * @supports ( mask-type: alpha ) is Firefox 35+
 */
@supports ( -webkit-appearance: none ) or ( appearance: none )
	or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {

  /* Show custom arrow */
  .custom-select::after {
    display: block;
  }

  /* Remove select styling */
  .custom-select select {
    padding-right: 2em; /* Match-01 */
    /* inside @supports so that iOS <= 8 display the native arrow */
    background: none; /* Match-04 */
    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 1px solid transparent; /* Match-05 */

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select select:focus {
    border-color: #aaa; /* Match-03 */
  }
}


*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  color: #303030;
  background: #f6f6f6;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  outline: none;
  color: #ffaf00;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
  outline: none;
}


/*Global Variables Here*/


@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

.blink {
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1110ms infinite;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.noscript{
  position:absolute;
  top:-10000px;
  width:1px;
  height:1px;
  overflow:hidden;
}